import React from 'react';
import styled from 'styled-components';
import { format, distanceInWords, differenceInDays } from 'date-fns';

import Button from 'reusecore/src/elements/Button';

import { buildImageObj } from '../lib/helpers';
import { imageUrlFor } from '../lib/image-url';
import PortableText from './portableText';
import it from 'date-fns/locale/it';

function BlogPost(props) {
  const {
    _rawBody,
    _rawExcerpt,
    title,
    mainImage,
    publishedAt,
  } = props;

  return (
    <article>
      <Centered>
        <Boxed>
          <Styles>
            <aside>
              {publishedAt && (
                <div>
                  {differenceInDays(new Date(publishedAt), new Date()) > 3
                    ? distanceInWords(new Date(publishedAt), new Date(), {
                        locale: it,
                      })
                    : format(new Date(publishedAt), 'DD MMMM YYYY', {
                        locale: it,
                      })}
                </div>
              )}
            </aside>
            <h1>{title}</h1>
            {mainImage && mainImage.asset && (
              <section>
                <figure>
                  <amp-img
                    src={imageUrlFor(buildImageObj(mainImage))
                      .width(680)
                      .height(Math.floor((9 / 16) * 680))
                      .fit('crop')
                      .auto('format')
                      .format('webp')
                      .url()}
                    alt={mainImage.alt}
                    width={680}
                    height={Math.floor((9 / 16) * 680)}
                  />
                  <figcaption>{mainImage.caption}</figcaption>
                </figure>
              </section>
            )}
            <div style={{ fontStyle: 'italic' }}>
              {_rawExcerpt && <PortableText blocks={_rawExcerpt} />}
            </div>
            {_rawBody && <PortableText blocks={_rawBody} />}
            <CtaSection>
              <h3>Sei alla ricerca del POS più conveniente?</h3>
              <Button
                href="/step-2/"
                title="Clicca qui per un preventivo gratuito"
                colors="primaryWithBg"
                amp
              ></Button>
            </CtaSection>
          </Styles>
        </Boxed>
      </Centered>
    </article>
  );
}

const CtaSection = styled.section`
  padding: 2em 1rem 3rem;
  text-align: center;
  background: #eee;
`;

const Styles = styled.div`
  p,
  li {
    font-family: Georgia, Cambria, 'Times New Roman', Times, serif;
    font-size: 18px;
    letter-spacing: -0.004em;
    line-height: 1.58;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  blockquote {
    color: rgba(0, 0, 0, 0.84);
  }

  h1 {
    @media screen and (max-width: 551.98px) {
      font-size: 30px;
    }
    font-size: 3rem;
    font-family: Georgia, Cambria, 'Times New Roman', Times, serif;
    font-weight: 400;
    margin: 1rem 0;
  }

  h2 {
    @media screen and (max-width: 551.98px) {
      font-size: 24px;
    }
  }

  figcaption {
    text-align: center;
    margin-top: 10px;
    color: rgba(0, 0, 0, 0.54);
  }
  aside {
    span,
    div {
      color: rgba(0, 0, 0, 0.84);
    }
  }

  figure {
    margin: 0;
    padding: 0;
    max-width: 100%;
  }

  img {
    width: 100%;
  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }

  ul {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 1.5em;
    margin-inline-end: 0px;
  }
`;

const Boxed = styled.div`
  max-width: 680px;
  width: 100%;
`;
const Centered = styled.section`
  display: flex;
  justify-content: center;
`;

export default BlogPost;
